.faq-container {
  scroll-behavior: smooth !important;

  .slide-container {
    width: 100%;
    position: relative;
    padding-left: 3px;
    padding-bottom: 3px;

    &:first-child {
      padding-top: 3px;
    }

    img {
      width: 100%;
    }

    .tools {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1000;
    }
  }

  .to-top {
    position: fixed;
    right: 10px;
    bottom: 5px;
    z-index: 1000001;
    color: teal;
    cursor: pointer;
  }
}
