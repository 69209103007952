.registration-container {

  .form-data label {
    display: flex;
  }

  .help-register {
    display: block;
    margin-left: 5px;
    cursor: pointer;
  }

  input:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    -webkit-appearance: none;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid teal;
    position: relative;
    transition: all 250ms ease-in-out;

    &:checked {
      background-color: teal;

      &:before {
        content: '\2713';
        position: absolute;
        color: white;
        left: 3px;
        top: -4px;
        bottom: 0;
        right: 0;
        margin: auto;
        font-size: 18px;

      }
    }
  }

  hr {
    border-color: teal;
  }

  user-select: none;
  padding: 10px 15px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  // border: 1px solid rgba(255, 0, 0, 0.34);

  .check-name-input {
    display: flex;
    flex: 1;
    width: 100%;
    gap: 5px;

    input {
      flex-grow: 10;
    }
  }

  .send-button button {
    margin-top: 10px;
    padding: 7px 15px !important;
  }

  .registration-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .registration__form-container {
      position: relative;
      border: 2px solid teal;
      border-radius: 5px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      gap: 3px;

      .form-data {
        display: flex;
        flex-direction: column;
        gap: 5px;

        input[type="range"] {
          padding: 0;
          -webkit-appearance: none;
          background-color: transparent;
          width: 100%;
          // margin-right: 10px;
          border: 1px solid teal;
          border-radius: 5px;

          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            background-color: white;
            border-radius: 50%;
            height: 25px;
            width: 25px;

            &:hover {
              background-color: #cdcdcd;
            }
          }
        }

        .fake-label {
          color: #ffffff;
          font-size: 12px;

          &-range {
            display: flex;
            justify-content: space-between;
          }
        }

        .error {
          color: red;
          font-size: 10px;
          font-weight: 400 !important;
          font-style: italic;
          letter-spacing: 0.6px;
        }

        label {
          color: #ffffff;
          font-size: 12px;

          span {
            color: red;
          }
        }

        input {
          outline: 0;
          border: 1px solid teal;
          background-color: #222222;
          color: #ffffff;
          padding: 5px;
          font-size: 13px;
          border-radius: 3px;
          z-index: 1;
        }
      }
    }
  }
}

.remove_container {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 15px;
  height: 15px;
  background-color: #222222;
  border-radius: 50%;
  border: 1px solid red;
  color: red;
  font-size: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: red;
  }
}

.reward-time-data {
  width: 100%;

  table {
    border-collapse: collapse;
    width: 100%;
    border-color: teal;
    border-radius: 5px;

    th, td {
      text-align: center;
      font-size: 10px;
      padding: 5px;
      color: #ffffff;
    }
  }
}

.check-player-name {
  flex-grow: 1;
  font-size: 10px;
  min-width: 40px;
  background-color: teal;
  border: 1px solid teal;
  border-radius: 3px;
  color: #ffffff;
  outline: 0;
  cursor: pointer;

  &:disabled {
    opacity: .3;
    pointer-events: none;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #222222;
    color: teal;
  }
}

.fake-input-listener {
  color: #ffffff;
  font-size: 13px;
  border-radius: 3px;
  border: 1px solid teal;
  width: 100%;
  padding: 5px;
  opacity: .6;
  cursor: not-allowed;
}

.MuiPickersCalendarHeader-label {
  text-transform: uppercase;
}

.MuiDialogActions-root {
  display: none !important;
}

.account-calendar-data {
  .standard-input-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100% !important;
    font-family: "Open Sans", sans-serif !important;
    flex-shrink: 1;

    .trash-birthday-button {
      position: absolute !important;
      right: 0 !important;
      border: 0 !important;
      border-radius: 50px !important;
      width: 30px !important;
      top: -1px;
    }

    .date-picker {
      width: 100%;
      position: relative;

      input {
        padding: 8.5px 14px;
      }

      .MuiInputAdornment-root {
        position: absolute;
        right: 35px;
      }

      .MuiInputBase-root {
        height: 30px !important;
      }
    }

    .birthday-greetings {
      width: 100%;

      &__container {
        border-radius: 4px;
        height: 30px;
        border: 1px solid teal;
        justify-content: center;
      }

      input {
        width: 100% !important;
        height: 100% !important;
      }

      label {
        width: 100%;
        padding: 0;
        margin: 0;

        span:first-child {
          padding: 0;
          padding-right: 5px;
        }
      }

      span {
        color: #ffffff !important;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-size: 12px !important;
        font-family: "Open Sans", sans-serif !important;
      }
    }
  }
}
