.titans_main_container {
  .selected_heroes__vs_icon {
    margin-bottom: 10px !important;
  }

  .item_disabled {
    pointer-events: none !important;
    opacity: 0.5;
  }

  .heroes_filter {
    margin-bottom: 15px !important;
  }

  .hero_item_titan {
    border: 0 !important;
    overflow: visible;
    margin-bottom: 5px;

    figcaption {
      z-index: 3;
      width: 65px;
      margin-bottom: -3px;
      margin-left: -3px;
      background-color: rgba(0, 0, 0, 0.45) !important;
    }

    &:hover:not(.hero_selected) {
      opacity: .7;
    }

    .titan-border {
      position: absolute;
      vertical-align: middle;
      width: 66px;
      height: 66px;
      inset: 0;
      margin: auto;
      z-index: 2;
      left: -3px;
      top: -1px;
    }

    .titan-img {
      vertical-align: middle;
      width: 60px;
      object-fit: cover;
      aspect-ratio: 1;
      border-radius: 5px;
      z-index: 1;
    }
  }

  .fight_settings {
    margin-top: 20px;

    .fight_count_container {
      margin-bottom: 15px !important;
    }

    input[type="number"] {
      width: 100%;
      border: 1px solid teal;
      background-color: transparent;
      border-radius: 5px;
      padding: 6px 10px;
      color: #ffffff;
      text-align: center;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

  }

  .selected_heroes__titan_power {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    margin-top: 10px;
    align-items: center;

    div:first-child {
      flex: 1;

      span {
        color: #ffffff;
        font-size: 13px;
        letter-spacing: 1.3px;
      }
    }

    div:last-child {
      flex: 5;
      width: 100%;

      input {
        width: 102%;
        background-color: inherit;
        outline: 0;
        border: 1px solid teal;
        border-radius: 4px;
        padding: 3px 10px;
        color: #ffffff;
        text-align: center;

        &:disabled {
          pointer-events: none !important;
          opacity: 0.7;
          border-color: #8a3b00;
        }
      }

      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }

  .selected_heroes__title {
    margin-bottom: 7px !important;
  }
}

.wars-top-container {
  width: 320px;
  margin: 15px auto -5px auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .hbs__top {
    display: flex;
    width: 100%;
    gap: 10px;

    .hbs__select {
      width: 100%;

      select {
        width: 100%;
      }

      &:last-child {
        width: 150px;
      }
    }
  }
}

.war-add-power-container {
  width: 320px;

  .fight_count_container {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    margin-top: -5px !important;
  }
}

.hero_is_dead {
  opacity: 0.3;
}

.del_war_top_container {
  display: flex;
  flex-direction: column;

  .last_fight_title {
    margin-bottom: -7px;
  }

  .last_fights_container__data {
    justify-content: space-between;
    width: 100%;
  }
}

.standard-input-block .MuiOutlinedInput-root.Mui-error fieldset {
  outline-color: #f44336 !important;
}
