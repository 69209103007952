.color-teal {
  color: teal;
}

.fw-600 {
  font-weight: 600;
}

.hero-modify {
  padding: 15px;
  width: 100%;
  max-width: 450px;
  margin: auto;

  .aliases {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .pack-aliases-list {
      i {
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }
    }
  }

  .modal {
    max-width: 360px;

    h2 {
      font-size: 17px;
    }

    margin-bottom: 50px;

    .modal-body {
      padding: 0;

      .hbs-container {
        .hbs__example {
          margin-top: 15px;
          margin-bottom: 15px;
          padding: 15px;
        }

        .hbs__uploaded-container {
          // padding-bottom: 80px;
        }

        .hbs__dropzone {
          padding: 0 15px 15px;
        }

        justify-content: flex-start;
        margin: 0;
      }
    }
  }

  .heroes_container {
    border-radius: 5px;

    .footer_icon {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 25px;
    }

    table {
      border-collapse: collapse;
      border-color: #444444;
      margin-top: 20px;

      tbody {
        tr {
          &.hero_item_row.hero_item_changed {
            background-color: #212d2d;
          }

          td {
            vertical-align: middle;
            padding: 5px;

            &:first-child {
              overflow: hidden;
              width: 67px;
              vertical-align: middle;

              img {
                vertical-align: middle;
                width: 55px;
                object-fit: cover;
                aspect-ratio: 1;
                border: 1px solid teal;
                border-radius: 5px;
              }
            }

            &:nth-child(2) {
              & > div {
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                width: 100%;

                .hero_name {
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  position: relative;

                  div {
                    width: 100%;

                    &:last-child {
                      position: absolute;
                      height: 3px;
                      width: 3px;
                      right: 0;
                      top: -8px;
                      z-index: 2;
                    }
                  }
                }

                .name_star {
                  align-self: flex-end;
                  color: #9aff47;
                  // margin-left: 100px;
                  display: block;
                }

                & > div:nth-child(2) {
                  display: flex;
                  gap: 10px;
                  align-content: center;
                  align-items: center;

                  > span {
                    width: 65px;
                    text-align: right;
                  }

                  input[type="number"] {
                    outline: 0;
                    border: 1px solid teal;
                    background-color: transparent;
                    color: #ffffff;
                    padding: 2px 5px;
                    text-align: right;
                    width: 100px;
                    -webkit-appearance: none;
                    border-radius: 5px;
                  }

                  /* Chrome, Safari, Edge, Opera */
                  input::-webkit-outer-spin-button,
                  input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }

                  /* Firefox */
                  input[type="number"] {
                    -moz-appearance: textfield;
                  }

                  button {
                    background-color: teal;
                    outline: 0;
                    border: 1px solid #005454;
                    height: 27px;
                    padding: 0 5px;
                    transition: all 250ms ease-in-out;
                    color: #ffffff;
                    border-radius: 5px;

                    &:hover {
                      border-color: #ababab;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .footer_totals {
    background-color: teal;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    padding: 5px;
    color: #ffffff;
    text-align: center;
    font-size: 13px;
  }
}

.hero-modify-view-only {
  padding: 40px 15px !important;
  // margin-top: -15px;
  table {
    margin-top: 10px !important;
  }

  .footer_totals {

  }
}

.modal-footer-hero-modify {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 390px;
  margin: auto;
  border-top-color: transparent !important;
  //background-color:#181818;

}
