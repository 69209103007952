.registration-toggle {
  // background: #222222;
  display: flex;
  min-height: 100%;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid teal;
  width: 100%;

  .btn {
    user-select: none;
    display: inline-block;
    padding: 5px 10px;
    position: relative;
    text-align: center;
    width: 100%;
    transition: background 600ms ease, color 600ms ease;
    letter-spacing: 0.5px;
    font-size: 12px !important;
  }

  input[type="radio"].toggle {
    display: none;

    & + label {
      cursor: pointer;
      min-width: 60px;

      &:hover {
        background: none;
      }

      &:after {
        background: teal;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
        width: 100%;
        z-index: -1;
      }
    }

    &.toggle-left + label {
      border-right: 0;

      &:after {
        left: 100%
      }
    }

    &.toggle-right + label {
      // margin-left: -5px;

      &:after {
        left: -100%;
      }
    }

    &:checked + label {
      cursor: default;
      color: #fff;
      transition: color 200ms;

      &:after {
        left: 0;
      }
    }
  }
}
