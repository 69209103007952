.edit-account-container {

  .common-settings-accordion {
    .Mui-expanded {
      margin: 12px 0;
    }
  }

  .registration-block {

  }

  .hbs__select {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;

    .hbs__title {
      color: #ffffff;
      font-size: 12px;
      margin-bottom: 5px;
      text-align: center;
      font-weight: 900;
    }

    select {
      width: 100% !important;
      margin-bottom: 0;
      border-width: 2px;
      max-width: 100%;
    }
  }

  .edit-values-container {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .values-data {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .sort-img-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        height: 65px;

        &.inverted {
          flex-direction: row;

          img {
            transform: scaleX(-1);
          }
        }
      }

      img {
        border-radius: 5px;
        overflow: hidden;
        border: 1px solid teal;
        width: 61px !important;
        height: 61px !important;
        min-width: 61px !important;
        // transition: all 250ms ease-in-out;
      }

      .fake-label {
        color: #ffffff;
        font-size: 12px;

        &-range {
          display: flex;
          justify-content: space-between;
        }
      }

      .error {
        color: red;
        font-size: 10px;
      }

      label {
        color: #ffffff;
        font-size: 12px;

        span {
          color: red;
        }
      }
    }
  }
}

.guild-search-container {

}

.table {
  width: 100%;
  border: 1px solid teal;
  border-collapse: collapse;

  tr, td {
    border: 1px solid teal;
    color: white;
  }

  td {
    font-size: 12px;
    padding: 5px;
  }
}

.add-guild-request {

  width: 50px;
}

.common-button {
  color: teal;
  background-color: inherit;
  outline: 0;
  border: 1px solid teal;
  padding: 5px 10px;
  margin: 0;
  width: 100%;
  letter-spacing: 0.7px;
  transition: all 250ms ease-out;
  cursor: pointer;

  &-inverted {
    @extend .common-button;
    color: white;
    background-color: teal;

    &:hover {
      background-color: #000000 !important;
      color: #ffffff !important;
    }
  }

  &:hover {
    background-color: teal;
    color: #000;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none !important;
  }
}

.cancel-guild-request {
  margin-top: 15px;
}
