.guild-edit-container {
  padding: 15px 15px 15px;

  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  letter-spacing: 0.4px;
  display: flex;
  flex-direction: column;

  .guild-server {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 12px;
    opacity: 0.5;
  }

  .guild-member-card {
    .dots-menu {
      width: 10px;
      font-size: 18px;
      text-align: left;
      align-content: center;
      opacity: 0.5;
      padding-right: 18px;
      display: block;
      align-self: flex-end;


      svg {
        // font-size: 16px;
      }
    }

    .gmc_rounded_item {
      color: teal;
      font-size: 10px;
      border-radius: 100%;
      border: 1px solid teal;
      padding: 2px;
      text-align: center;
      aspect-ratio: 1;
      line-height: 12.5px;
      margin-top: 2px;
      font-weight: 900;
      cursor: default;
      height: 20px;
    }

    background-color: #222222;

    &--isMe {
      border-color: green !important;
    }

    &--general {
      border-color: #e36000;
    }

    &--master {
      border-color: goldenrod;
    }

    &--isActive {
      margin-top: 30px !important;
      position: relative;

      &:before {
        position: absolute;
        content: attr(data-active-text);
        height: 30px;
        width: 200px;
        text-align: center;
        top: -20px;
        right: 0;
        padding-top: 1px;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.6px;
        background-color: #005b00;
        color: #ffffff;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        z-index: -1;
      }
    }

    &.guild-member-card-request {
      border-color: darkorange;

      .user-icon {
        transition: all 0.05s ease-out !important;
        // animation: eye-animation 0.2s infinite;
      }

      @keyframes eye-animation {
        0% {
          transform: scaleX(1);
        }
        100% {
          transform: scaleX(-1);
        }
      }

    }

    .block {
      display: flex;
      justify-content: space-between;
    }

    .top-block {
      width: 100% !important;
      gap: 10px;

      .name {
        flex-grow: 1;
      }

      .telegram {
        flex-grow: 1;
      }

      .achievement-hour {
        flex-grow: 0;
      }
    }

    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    padding: 7px;
    border: 2px solid teal;
    border-radius: 7px;
    row-gap: 7px;

    .block:nth-child(1) {
      margin-bottom: -4px;
    }

    .top-name-block {
      margin-bottom: -4px;
    }

    .icon-block {
      span {
        display: block;
      }

      display: flex;
      align-items: center;
      gap: 4px;

      &.name-block {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        width: 100%;

        div {
          gap: 3px;
          display: flex;
        }
      }

    }

    .name {
      font-weight: 600;
      font-size: 16px;
      display: flex;
      width: 100%;
    }

    .telegram {
      font-size: 10px;
    }

    .position {
      font-size: 10px;
      width: 120px;

      svg path {
        fill: goldenrod !important;
      }
    }

    .achievement-hour {
      font-size: 10px;

      span {
        width: 28px;
        text-align: right;
      }
    }

    // Герои
    .g-heroes-block {
      .g-heroes {
        width: 100%;
        table-layout: fixed;

        td {
          font-size: 10px !important;
          vertical-align: middle;
        }

        .sep {
          width: auto;
        }
      }

      .g-power-title {
        width: 36px;
      }

      .g-power {
        width: 53px;
      }

      .g-power-percent {
        width: 36px;
      }

      .g-heroes-count {
        width: 17px;
      }

      .g-updated {
        width: 87px;
      }

      .time-block-icon {
        width: 14px !important;
      }

      .time-block {
        width: 90px !important;
      }
    }

    .g-titans-block {

    }

    .g-subtitle {
      font-weight: 600;
    }
  }
}

.text-right {
  text-align: right !important;
}

.guild-requests-container {
  margin-bottom: 15px;
}

.guild-title {
  text-align: center;
  font-weight: 600;
  margin-bottom: 5px;
}

.requests-action-buttons {
  gap: 5px;

  button {
    width: 100%;
    @extend .common-button-inverted;
    font-size: 11px;
    padding: 3px;

    &.decline {
      background-color: darkred;
      border-color: darkred;
    }
  }
}

.color-red {
  color: #e36a6a;
}

.standard-input-block {
  display: flex;
  flex-direction: column;
  // gap: 3px;
  // margin-bottom: 15px !important;

  &.inverse {
    filter: invert(20%);
  }

  label {
    color: teal;
    font-weight: 600;
  }

  .MuiSvgIcon-root {
    fill: teal;
  }

  .MuiOutlinedInput-root, .MuiSelect-select, .Mui-focused {
    color: #ffffff;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }

    fieldset {
      outline-color: teal;
      border-color: teal;
    }

    &:hover, &:focus {
      fieldset {
        outline-color: #00bbbb;
        border-color: #00bbbb;
      }
    }
  }

  //label {
  //  font-weight: 600;
  //  font-size: 12px;
  //  color: teal;
  //}
  //
  //.input {
  //  input {
  //    outline: 0;
  //    border: 1px solid teal !important;
  //    background-color: inherit;
  //    color: #ffffff;
  //    padding: 5px;
  //    font-size: 13px;
  //    border-radius: 3px;
  //    z-index: 1;
  //    width: 100%;
  //  }
  //}
}

.guild-edit-dots-menu-container {
  li {
    svg {
      opacity: 0.6 !important;
      margin-right: 7px;
    }
  }
}

.standard-input-select-dropdown {
  z-index: 999999 !important;
}

.guild-manage-modal {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .MuiSvgIcon-root {
    color: teal !important;
  }

  .Mui-checked {
    // color: teal !important;
  }

  .loader {
    top: 5px !important;
  }
}

.guild-manage-card {
  gap: 10px;

  &--chats {
    gap: 15px;
  }

  &--subtitle {
    text-align: left !important;
    margin-bottom: 15px;
  }

  .subtitle {
    margin-bottom: 10px;
    text-align: left;
  }
}

.swal2-container {
  z-index: 999999999 !important;

  &.swal2-backdrop-show {
    background: rgb(20 20 20 / 99%) !important;
  }
}

.guild-server-change-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 998;
  background: rgb(20 20 20 / 99%) !important;
  padding: 30px 30px;

  b {
    font-size: 18px;
  }

  p {
    font-size: 16px;
  }

  .bottom-buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 10px;

    button {
      width: 100%;
      letter-spacing: 0.8px;
    }
  }
}

// Guild creation
.guild-creation-container {
  position: relative !important;
  background-color: #151515 !important;
  height: 100vh;
  z-index: 0 !important;

  .modal {
    background-color: #181818;
    position: relative;
    inset: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border: 0;
  }
}

.toast-up-modal {
  z-index: 999999999 !important;
}

#guild-edit-menu {
  hr {
    margin: 0 !important;
  }
}

.guild-edit-menu--items {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
