.transfer-account {
  &-container {
  }

  &__top {
    text-align: center;
  }

  &__bottom {
    margin-top: 10px;
  }

  &__info {
    display: block;
    padding: 10px;
    border: 1px solid teal;
    border-radius: 5px;
    margin: 10px 0;
  }

}
