.heroes_main_container .hero_item_titan {
  border: 0 !important;
  overflow: visible;
  margin-bottom: 5px;

  figcaption {
    z-index: 3;
    width: 65px;
    margin-bottom: -3px;
    margin-left: -3px;
    background-color: rgba(0, 0, 0, 0.45) !important;
  }

  &:hover:not(.hero_selected) {
    opacity: .7;
  }

  .titan-border {
    position: absolute;
    vertical-align: middle;
    width: 66px;
    height: 66px;
    inset: 0;
    margin: auto;
    z-index: 2;
    left: -3px;
    top: -1px;
  }

  .titan-img {
    vertical-align: middle;
    width: 60px;
    object-fit: cover;
    aspect-ratio: 1;
    border-radius: 5px;
    z-index: 1;
  }
}

.guild-modal-heroes {
  .modal-body {
    padding: 0 !important;
  }

  .hero_container .hero_item figcaption {
    font-size: 9px;
  }

  .hbs__select {
    optgroup {
      background-color: #000;
      color: #969696 !important;
      font-weight: 600;
    }

    width: 100%;

    select {
      width: 100%;
    }
  }

  .selected_heroes__reverse {
    .selected_heroes {
      .selected_heroes__data {
        flex-direction: row-reverse;

        img {
          transform: scaleX(1) !important;
        }
      }
    }
  }

  .top-c-select {
    margin-bottom: 10px;

    .top-c {
      &__control {
        border: 1px solid teal;
        border-radius: 3px;
        background-color: #222222;
        outline: 0 !important;
        color: #ffffff !important;
      }

      &__control {
        outline: 0 !important;
      }

      &__single-value {
        color: #ffffff;
        outline: 0 !important;
      }

      &__input-container {
        color: #ffffff;
        outline: 0 !important;
      }

      &__menu {
        background-color: #222222;
        border: 1px solid teal;
        color: #ffffff;
      }

      &__option--is-selected {
        background-color: rgba(255, 255, 255, .2);
        color: #ffffff;
      }

      &__option {
        background-color: rgba(255, 255, 255, 0);
        color: #ffffff;
      }

      &__option:hover {
        background-color: rgba(255, 255, 255, .2);
      }
    }
  }
}

.heroes_result_container {
  padding: 15px;
}

.footer-btns {
  display: flex;
  justify-content: space-between;
}

.cp_hidden {
  display: none;
}
