.menu-container {
  padding: 15px 10px;

  .bottom-link {
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
  }

  .menu-birthday {
    &-top {
      // border: 1px solid orangered;
      margin-top: -5px;

      a {
        color: inherit;
        text-decoration: none;
        letter-spacing: 0.2px;
      }
    }

    &-cake {
      color: orangered;
    }
  }

  .menu-active_player {
    &-top {
      padding: 2px 0 0;
      margin-top: -5px;
      background-color: darken(#313131, 2%);
      text-align: center;
      cursor: default;

      & > div {
        text-align: center;
        width: 100%;
      }
    }

    &-cake {
      color: orangered;
    }
  }


  .menu-paper-block-container {
    overflow: hidden;

    .menu-list-item {

      &__with-description {
        .menu-list-item__button {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }

        .menu-list-item__text {
          margin-top: 5.5px;
          margin-bottom: 5.5px;
        }
      }


      &__text {
        .MuiListItemText-secondary {
          font-style: italic;
          font-size: 9px;
          opacity: 0.7;
        }
      }
    }

    &.menu-disabled {
      .MuiListItem-root {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    img {
      width: 100%;
      height: 99px;
    }
  }

  li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.12);

    &:last-child {
      border-bottom: none;
    }

    div {
      // text-align: center;
    }

    span {
      letter-spacing: 0.6px !important;
    }
  }
}
