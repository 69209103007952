.titan-modify {
  .hero_item_titan {

    td {
      position: relative;
    }

    img {
      border: 0 !important;
    }

    .titan-border {
      position: absolute;
      vertical-align: middle;
      width: 60px;
      height: 60px;
      inset: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    .titan-img {
      vertical-align: middle;
      width: 55px;
      object-fit: cover;
      aspect-ratio: 1;
      border-radius: 5px;
    }
  }
}
