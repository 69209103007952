.review-block {
  .infinite-scroll-component {
    overflow: visible !important;
  }

  padding: 0;

  .hbs__select select {
    width: 100%;
  }

  .top-c__menu-list {
    min-height: 85vh;
  }

  .top-block {
    position: fixed !important;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    background-color: #222222;
    font-size: 14px;

    .top-container {
      padding: 5px;
    }
  }

  .main-block {
    margin-top: 110px;
  }

  .top-c-select {
    .top-c {
      &__control {
        border: 1px solid teal;
        border-radius: 3px;
        background-color: #222222;
        outline: 0 !important;
        color: #ffffff !important;
      }

      &__control {
        outline: 0 !important;
      }

      &__single-value {
        color: #ffffff;
        outline: 0 !important;
      }

      &__input-container {
        color: #ffffff;
        outline: 0 !important;
      }

      &__menu {
        background-color: #222222;
        border: 1px solid teal;
        color: #ffffff;
      }

      &__option--is-selected {
        background-color: rgba(255, 255, 255, .2);
        color: #ffffff;
      }

      &__option {
        background-color: rgba(255, 255, 255, 0);
        color: #ffffff;
      }

      &__option:hover {
        background-color: rgba(255, 255, 255, .2);
      }
    }
  }
}
