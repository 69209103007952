.hbs-container {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;

  margin: 10px auto;

  .hbs__top {

  }

  .hbs__dropzone {
    text-align: left;
    padding: 10px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: column;
    user-select: none;

    section {
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: flex-start;
      color: #ffffff;
      // min-height: 50px;
      padding: 5px 0;
      border: 3px dashed teal;
      border-radius: 5px;
      width: 100%;
      max-width: 400px;
      cursor: pointer;
      outline: 0;

      flex-direction: column;

      button {
        width: 100%;
        max-width: 250px;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 5px auto 5px auto;
        background-color: teal;
        border-color: #212d2d;
        outline-color: #212d2d;
      }

      & > .hbs__droproot {
        padding: 15px;
        width: 100%;
        min-height: 150px;
        display: flex;
        align-items: center;
      }
    }
  }

  .hbs__legend {
    color: #ffffff;
    padding: 15px 0 0;
    font-size: 12px;
    width: 100%;
    max-width: 400px;
  }


  .hbs__uploaded {
    border: 3px solid teal;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
    width: 100%;
    max-width: 400px;
    padding: 5px 10px;
    height: 100%;
    color: #ffffff;
    font-size: 15px;

    &-item {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > div {
        flex: 1;
      }

      .hbs__short_name {
        flex: 17;
        cursor: zoom-in;
      }
    }
  }
}

.hbs__uploaded-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  padding: 0 15px 15px;
  user-select: none;
}

.hbs__remove_item {
  color: #e55858;
  cursor: pointer;
  user-select: none;

  &:hover {
    color: red;
  }
}

.hbs__example {
  font-size: 13px;
  color: teal;
  user-select: none;
  cursor: zoom-in;
  border: 1px solid teal;
  border-radius: 4px;
  width: 200px;
  height: 32px;
  letter-spacing: 1px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: teal;
    color: #ffffff;

  }

}

.hbs__select {
  width: 100%;

  select {
    width: 100%;
    max-width: 200px;
    padding: 5px 10px;
    border: 1px solid teal;
    border-radius: 5px;
    background-color: transparent;
    color: #ffffff;
    outline: 0 !important;
    margin-bottom: 10px;

    /* turn it off completely */
    &:active, &:hover {
      outline: none
    }

    /* make it red instead (with with same width and style) */
    &:active, &:hover {
      outline-color: red
    }

    option {
      color: #ffffff;
      background-color: #222;
      border-color: teal;
    }
  }
}

.loading-history-container {
  width: 100%;

  padding-top: 30px;

  .lh-card {
    width: 100%;
    font-size: 0.8rem;

    &__player {
      width: 120px;
    }

    &__content {
      justify-content: space-between;
      display: flex;
      gap: 20px;
      width: 100%;
    }

    &__info {
      width: 100%;
      display: flex;
      align-self: flex-end;
      text-align: right;
      gap: 10px;
    }
  }

}

.guild-uploads-summary-top {
  position: fixed;
  z-index: 9999999;
  background-color: #222;
  height: 40px;
  top: 0;
  right: 0;
  left: 0;

  h3 {
    padding-top: 5px;
  }
}

.summary-static-date-picker {
  position: fixed;
  top: 50px;
  z-index: 999999;
  color: #fff;

  background-color: #222;

  .MuiPickersLayout-root {
    background-color: inherit;
    border: 2px solid teal;
    border-radius: 5px;
  }

  //.MuiPickersArrowSwitcher-root {
  //  display: none;
  //}

  .MuiPickersCalendarHeader-label {
    text-transform: uppercase;
  }

}
